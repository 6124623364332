<template>
  <div v-if="!isLoading" class="py-4 container">
    <div class="row">
      <button
        :class="
          isTodayCheckInDay
            ? 'btn btn-success btn-lg'
            : 'btn btn-outline-info btn-lg'
        "
        :disabled="!isTodayCheckInDay"
        @click="() => this.$router.push({ name: 'CheckIn' })"
      >
        {{ checkinText }}
      </button>
      <default-statistics-card
        title="Average Intake"
        class="mb-2"
        :count="averageNutritrion.calories + ' calories'"
        :percentage="{
          color: 'success',
          value: caloriePercentage + '%',
          label: 'of goal intake',
          label: 'within goal intake',
        }"
        menu="Past 7 Days"
      />
      <default-statistics-card
        title="Average Protein"
        class="mb-2"
        :count="averageNutritrion.protein + ' grams'"
        :percentage="{
          color: 'success',
          value: proteinPercentage + '%',
          label: 'of goal intake',
          label: 'within goal intake',
        }"
        menu="Past 7 Days"
      />
      <default-statistics-card
        title="Consitency"
        class="mb-2"
        :count="consistency + '%'"
        :percentage="{
          color: consistency > 80 ? 'success' : 'danger',
          value: 'days',
          label: 'of goal intake',
          label: 'within goal intake',
        }"
      />
      <div class="mt-4 mb-4 col-lg-0 col-sm-0 mt-sm-0">
        <daily-weights :user-id="userId" />
      </div>
      <default-statistics-card
        v-if="goalType"
        title="Current Plan"
        class="mb-2"
        :count="goalType"
        :percentage="{
          color: 'success',
          value: Math.round(tdee) + ' cal',
          label: 'TDEE',
        }"
        menu=""
      />
      <default-statistics-card
        v-if="goalType"
        title="TDEE Change"
        class="mb-2 dahsboard-hidden-desktop"
        :count="Math.round(tdee) + ' calories'"
        :percentage="{
          color: 'success',
          value: Math.round(previousTdee) + ' prev',
          label: Math.round(tdee - previousTdee) + ' cal change',
        }"
        menu="Past 7 Days"
      />
      <default-statistics-card
        v-if="goalWeight"
        title="Goal Weight"
        class="mb-2"
        :count="goalWeight"
        :percentage="{
          color: 'success',
          value: goalRate,
          label: '% b/w per week',
        }"
        menu="Past 7 Days"
      />
      <default-statistics-card
        v-if="dateGoalReached"
        title="Goal Reached"
        class="mb-2"
        :count="dateGoalReached"
        :percentage="{
          color: 'success',
          value: 'approx',
          label: 'date',
        }"
      />
      <div class="mt-4 col-lg-0 col-sm-0 mt-sm-3">
        <weight-chart
          :dates="fourWeekAverageWeights.dates"
          :weights="fourWeekAverageWeights.weights"
        />
      </div>
      <div class="mt-4 col-lg-0 col-sm-0 mt-sm-3">
        <macros-chart-card :user-id="userId" />
      </div>
    </div>
    <div class="card z-index-2 mt-5">
      <div class="card-header p-3 pt-2">
        <div
          class="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 me-3 float-start"
        >
          <i class="material-icons opacity-10">data_saver_on</i>
        </div>
        <h6 class="mb-0">Biometrics</h6>
        <p class="mb-0 text-sm">Weekly score</p>
      </div>
      <div class="p-5 card-body">
        <div>No Data Yet...</div>
        <!-- <radar-chart
          :chart="{
            labels: [
              'Hunger',
              'Sleep',
              'Stress',
              'Cravings',
              'Energy',
              'Digestion',
            ],
            datasets: [
              {
                label: 'This Week',
                data: [5, 4, 2, 3, 2, 5],
              },
              {
                label: 'Last Week',
                data: [4, 5, 3, 4, 2, 5],
              },
            ],
          }"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import DailyWeights from "./components/DailyWeights.vue";
//import RadarChart from "../pages/components/RadarChart.vue";
import MacrosChartCard from "./components/MacrosChartCard.vue";
import UserSettings from "../../data/user/userSettings";
import WeightChart from "./components/WeightChart.vue";
import DefaultStatisticsCard from "./components/DefaultStatisticsCard.vue";
import supabase from "../../data/createClient";

export default {
  name: "Overview",
  components: {
    DailyWeights,
    //RadarChart,
    MacrosChartCard,
    WeightChart,
    DefaultStatisticsCard,
  },
  data() {
    return {
      isLoading: true,
      userId: null,
      fourWeekAverageWeights: {
        dates: [],
        weights: [],
      },
      averageNutritrion: {
        calories: 0,
        protein: 0,
      },
      goalNutrition: {
        calories: 0,
        protein: 0,
      },
      consistency: 0,
      goalWeight: 0,
      goalType: "",
      goalRate: 0,
      tdee: 0,
      previousTdee: 0
    };
  },
  computed: {
    caloriePercentage() {
      if (this.averageNutritrion.calories == 0) return 0;
      if (this.goalNutrition.calories == 0) return 0;
      return Math.round(
        (this.averageNutritrion.calories / this.goalNutrition.calories) * 100
      );
    },
    proteinPercentage() {
      if (this.averageNutritrion.protein == 0) return 0;
      if (this.goalNutrition.protein == 0) return 0;
      return Math.round(
        (this.averageNutritrion.protein / this.goalNutrition.protein) * 100
      );
    },
    dateGoalReached() {
      if (this.fourWeekAverageWeights.weights.length == 0) return null;

      if (!this.fourWeekAverageWeights) return null;

      const currentWeight = this.fourWeekAverageWeights.weights[
        this.fourWeekAverageWeights.weights.length - 1
      ];
      const goalWeight = this.goalWeight;
      const goalRate = this.goalRate;

      if (goalRate == 0 || goalWeight == 0) return "Goal Acheived";
      const weightPerWeek = currentWeight * (goalRate / 100);

      const weeksToGoal = (currentWeight - goalWeight) / weightPerWeek;

      if (weeksToGoal < 0) return "Goal Acheived";

      const today = new Date();
      const dateGoalReached = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + weeksToGoal * 7
      );

      return dateGoalReached
        .toDateString()
        .substring(0, dateGoalReached.toDateString().length - 4);
    },
    checkinText() {
      //if today is sunday, monday or tuesday, make the checkin button available
      const today = new Date();
      const day = today.getDay();

      if (day == 0 || day == 1 || day == 2) {
        return "Check In Now";
      }

      //calculate how many days until sunday

      const daysUntilSunday = 7 - day;

      return "Check In Available in " + daysUntilSunday + " days";
    },
    isTodayCheckInDay() {
      const today = new Date();
      const day = today.getDay();

      if (day == 0 || day == 1 || day == 2) {
        return true;
      }

      return false;
    },
  },
  async beforeCreate() {
    this.userId = await UserSettings.getUserId();
    await this.getFourWeekAverageWeight();
    await this.getAverageCaloriesAndProtein();
    await this.getConsistency();
    await this.getPlanMetrics();
    await this.getTDEE();
  },
  methods: {
    async getFourWeekAverageWeight() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data, status } = await supabase.rpc("get_last_4_weeks_weight", {
          _id: this.userId,
        });

        if (status != 200) {
          console.error("Error fetching data");
        } else {
          this.data = data;
          this.fourWeekAverageWeights.dates = data.map(
            (item) => item.week_start_date
          );
          this.fourWeekAverageWeights.weights = data.map(
            (item) => item.avg_weight
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getAverageCaloriesAndProtein() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data, status } = await supabase.rpc("averagenutrition", {
          _id: this.userId,
        });

        const { data: goalData, status: goalStatus } = await supabase.rpc(
          "goalnutrition",
          {
            _id: this.userId,
          }
        );

        if (status != 200 && goalStatus != 200) {
          console.error("Error fetching data");
        } else {
          if (data.length != 0) {
            this.averageNutritrion.calories = data[0].calories;
            this.averageNutritrion.protein = data[0].protein;
          }

          if (goalData.length != 0) {
            this.goalNutrition.calories = goalData[0].calories;
            this.goalNutrition.protein = goalData[0].protein;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getConsistency() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data, status } = await supabase.rpc("consistencypercentage", {
          _id: this.userId,
        });

        if (status != 200) {
          console.error("Error fetching data");
        } else {
          if (data.length != 0) {
            this.consistency = data;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getPlanMetrics() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data } = await supabase
          .from("plans")
          .select()
          .eq("user_id", this.userId)
          .is("end_date", null);

        if (data.length == 0) {
          console.error("Error fetching data");
        } else {
          if (data.length != 0) {
            this.goalWeight = data[0].target_weight;
            this.goalRate = data[0].target_rate;
            const goalType = data[0].goal;

            if (goalType == "0") {
              this.goalType = "Weight Loss";
            } else {
              this.goalType = "Maintanence";
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getTDEE() {
      if (!this.userId) return console.error("No user id provided");

      try {
        const { data } = await supabase
          .from("tdeedetails")
          .select()
          .eq("user_id", this.userId)
          .is("date_end", null);

        if (data.length == 0) {
          console.error("Error fetching data");
        } else {
          if (data.length != 0) {
            this.tdee = data[0].tdee;
          }
        }

        if (data.length != 0) {
          const { data: previousData } = await supabase
          .from("tdeedetails")
          .select()
          .eq("user_id", this.userId)
          .order('date_end', { ascending: true })

          if (previousData.length > 2) {
            this.previousTdee = previousData[previousData.length - 2].tdee;
        }
      }
        
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>

.dahsboard-hidden-desktop {
  display: none;
}

@media screen and (max-width: 570px){
  .dahsboard-hidden-desktop {
  display: block;
}  
}
</style>

