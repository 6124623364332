import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Pricing from "../views/pages/Pricing.vue";
import NewUser from "../views/user/NewUser.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import SignIn from "../views/auth/signin/SignIn.vue";
import SignUp from "../views/auth/signup/SignUp.vue";
import supabase from "../data/createClient.js";
import TidyCal from "../views/applications/TidyCal.vue";
import NutritionLog from "../views/nutrition/NutritionLog.vue";
import NutritionOverview from "../views/dashboards/Overview.vue";
import MacroCalculator from "@/views/macrocalculator/MacroCalculator.vue";
import FormView from "@/views/checkin/FormView.vue";
import Assistant from "@/views/macrocalculator/Assistant.vue";
import PlanSetup from "@/views/plan/PlanSetup.vue";
import store from "@/store";
import { logError } from "../errors/rollbar.js";
import AddReceipe from "@/views/macrocalculator/AddReceipe.vue";
import Chat from "@/views/applications/Chat.vue";
import ResetCover from "../views/auth/reset/Cover.vue";

const routes = [
  {
    path: "/overview",
    name: "Overview",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Macro Calculator",
    component: MacroCalculator,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/addrecipe",
    name: "Add Recipe",
    component: AddReceipe,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assistant",
    name: "Assistant",
    component: Assistant,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking",
    name: "Bookings",
    component: TidyCal,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/nutrition",
    name: "NutritionLog",
    component: NutritionLog,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/overview",
    name: "Nutrition Overview",
    component: NutritionOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkin",
    name: "CheckIn",
    component: FormView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/plan",
    name: "Plan",
    component: PlanSetup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/logout",
    meta: {
      logout: true,
    },
  },
  {
    path: "/signup-activate-new-user/:session",
    name: "Signup",
    component: SignUp,
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    meta: {
      requiresAuth: true,
    },
  },
  //other routes
  {
    path: "/dashboards",
    name: "Default",
    component: Default,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
    meta: {
      requiresAuth: true,
      onBoarding: true,
    },
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/logout") {
    await supabase.auth.signOut();
    router.push("/login");
  }

  if (to.meta.requiresAuth && !to.meta.onBoarding) {
    try {
      const { data } = await supabase.auth.getSession();

      if (data.session) {
        if (store.state.onboardingComplete === false) {
          router.push("/pages/users/new-user");
        } else {
          next();
        }
      } else {
        router.push("/login");
      }
    } catch (error) {
      logError(error);
    }
  } else {
    next();
  }
});

export default router;
