<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-gradient opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Reset Password
                </h4>
                <p class="container text-white text-center mt-2 mb-0">
                  Enter your email address and we'll send you a link to reset
                  your password.
                </p>
              </div>
            </div>

            <div class="card-body">
              <form role="form" class="text-start mt-3">
                <div class="mb-3">
                  <material-input
                    id="email"
                    v-model="email"
                    type="email"
                    label="Email"
                    placeholder="john@email.com"
                    name="email"
                    variant="static"
                  />
                </div>
                <div class="text-center">
                  <material-button
                    class="mt-4"
                    variant="gradient"
                    color="info"
                    full-width
                    @click.prevent="reset"
                    >Reset</material-button
                  >
                  <material-button
                    class="mt-2"
                    variant="gradient"
                    color="grey"
                    full-width
                    @click.prevent="back"
                    >Back</material-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }} NutriCoachMe
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.nutricoachme.com"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Home</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import supabase from "../../../data/createClient";

export default {
  name: "ResetCover",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      email: "",
      isLoading: false,
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async reset() {
      this.isLoading = true;

      const baseUrl = this.$route.path.split("/reset")[0];

      const { error } = await supabase.auth.resetPasswordForEmail(this.email, {
        redirectTo: baseUrl + "update-password",
      });

      this.isLoading = false;

      if (error) {
        this.$swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#6658dd",
        });
        return;
      }

      this.$swal.fire({
        title: "Success!",
        text: "Check your email for the reset link",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#6658dd",
      });
    },
    back() {
      this.$router.push({ name: "SignIn" });
    },
  },
};
</script>
