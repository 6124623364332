<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Macros</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          data-bs-original-title="See current macros"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row" v-if="!isLoading">
        <div class="col-15 text-start">
          <pie-chart 
            :chart="{
              labels: ['Protein %', 'Carbs %', 'Fats %'],
              datasets: {
                label: 'Macros',
                data: [macros.protein.toNonFatCaloriePercentage(macros.calories)
                , macros.carbs.toNonFatCaloriePercentage(macros.calories)
                , macros.fats.toCaloriePercentage(macros.calories)],
              },
            }"
          />
        </div>
        <div class="my-auto col-15 d-flex justify-content-center">
          <span class="badge badge-md badge-dot me-4 d-block">
            <i class="bg-info"></i>
            <span class="text-xs text-dark">Protein: {{ this.macros.protein }}g</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-primary"></i>
            <span class="text-xs text-dark">Carbs: {{ this.macros.carbs }}g</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-dark"></i>
            <span class="text-xs text-dark">Fats: {{ this.macros.fats }}g</span>
          </span>
        </div>
      </div>
    </div>
    <div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
      <div class="w-60">
        <p class="text-sm">
          These are your current macros
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/examples/Charts/PieChart.vue";
import supabase from "../../../data/createClient";
import '@/utils/nutritionExtensions.js';

export default {
  name: "MacrosChartCard",
  components: {
    PieChart,
  },
  props: {
    userId: {
      type: Number,
      default: 2,
    },
    prop: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fats: 0
    },
  },
  data() {
    return {
      isLoading: true,
      macros: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fats: 0
      },
    };
  },
  async mounted() {
    if (!this.prop) {
      await this.getMacros();
    } else {
      this.macros.calories = this.prop.calories;
      this.macros.protein = this.prop.protein;
      this.macros.carbs = this.prop.carbs;
      this.macros.fats = this.prop.fats;
    }
  },
  methods: {
    async getMacros() {
      if (!this.userId) 
        return console.error('No user id provided')

      try {
        const { data, error } = await supabase
          .from('nutritiondetails')
          .select('calories, protein, carbs, fats, users!inner(id)')
          .eq('users.id', this.userId)
          .single()

        if (error) {
          console.error('Error fetching data:', error.message);
        } else {
          console.log('Fetched data:', data);
          this.macros.calories = data.calories;
          this.macros.protein = data.protein;
          this.macros.carbs = data.carbs;
          this.macros.fats = data.fats;
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
 