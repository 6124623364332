<template>
  <div class="container">
    <h1 class="text-center pt-0">Add Recipe</h1>

    <div>
      <label for="recipeName">Recipe Name:</label>
      <material-input
        id="recipeName"
        v-model="recipeName"
        type="text"
        required
      />
      <div v-if="recipeNameValidation" class="text-danger">
        Please add a recipe name
      </div>
    </div>

    <div>
      <label for="totalServings">Total Servings:</label>
      <material-input
        id="totalServings"
        v-model="totalServings"
        type="number"
        required
      />
    </div>

    <div class="pt-2">
      <material-button type="button" @click="addIngredient"
        >Add Ingredient</material-button
      >
    </div>

    <!-- Search Modal -->
    <div v-show="showFoodModal" title="Add Food">
      <div class="d-flex flex-column">
        <hr />
        <div class="pt-2 d-flex justify-content-center">
          <input
            id="Search for your food here"
            v-model="searchText"
            type="text"
            class="form-control"
            placeholder="Search"
          />
          <material-button
            variant="outline"
            color="success"
            size="sm"
            @click="searchForFood()"
          >
            Go
          </material-button>
        </div>
        <div
          v-if="isSearchLoading"
          class="d-flex align-items-center justify-content-center"
        >
          <div
            class="spinner-border"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div>
          <div v-if="searchedItems.length > 0" class="scrollable-list">
            <div
              v-for="(item, index) in searchedItems"
              :key="index"
              class="list-item"
              @click="addItem(item)"
            >
              <div class="text-left">
                <strong>{{ item.food }}</strong>
              </div>
              <div v-if="item.protein" class="px-2">
                p: {{ item.protein }} c: {{ item.carbs }} f: {{ item.fats }}
                {{ item.weight > 0 ? "per " + item.weight + " g" : "per 100g" }}
              </div>
              <div class="px-2">
                {{ item.brand }}
              </div>
            </div>
          </div>
        </div>
        <div class="pt-2 d-flex justify-content-end">
          <div class="form-check form-switch d-flex align-items-center">
            <input
              id="brandedSerach"
              v-model="myFoodsSearch"
              class="form-check-input m-2"
              type="checkbox"
            />
            <label class="form-check-label mt-2 mx-2" for="brandedSearch">
              My Foods
            </label>
            <input
              id="brandedSerach"
              v-model="brandedSearch"
              class="form-check-input m-2"
              type="checkbox"
            />
            <label class="form-check-label mt-2 mx-2" for="brandedSearch">
              Branded Items
            </label>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <!-- Search food Open Modal -->
    <div
      v-if="showSearchFoodModal"
      :style="{ display: showSearchFoodModal ? 'block' : 'none' }"
      class="modal"
    >
      <div class="modal-content">
        <div
          v-if="isSearchLoading"
          class="d-flex align-items-center justify-content-center m-5"
        >
          <div
            class="spinner-border"
            role="status"
            style="width: 3rem; height: 3rem"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="py-10"></div>
        </div>
        <div v-else>
          <span class="close" @click="showSearchFoodModal = false"
            >&times;</span
          >
          <h2 class="text-center">{{ selectedSearchItem.food }}</h2>
          <form @submit.prevent="confirmAddItem">
            <div class="form-group">
              <div class="d-flex pb-2">
                <input
                  id="amount"
                  v-model="selectedSearchItem.weight"
                  type="text"
                  class="form-control outlined-input"
                  placeholder="Amount (g)"
                />
                <div v-if="selectedSearchItem.weight > 0" class="p-2">g</div>
              </div>
              <select
                v-if="selectedSearchItem.foodPortions.length > 0"
                v-model="selectedSearchItem.weight"
                class="form-select p-2"
              >
                <option
                  v-for="(portion, index) in selectedSearchItem.foodPortions"
                  :key="index"
                  :value="portion.gramWeight"
                >
                  {{ portion.amount }}
                  {{ portion.measureUnit ? portion.measureUnit.name : "" }}
                </option>
              </select>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Nutrition</th>
                  <th>
                    {{
                      selectedSearchItem.weight > 0
                        ? "per " + selectedSearchItem.weight + " g"
                        : "Per 100g"
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Protein</td>
                  <td>{{ searchProteinAmount }}g</td>
                </tr>
                <tr>
                  <td>Carbs</td>
                  <td>{{ searchCarbsAmount }}g</td>
                </tr>
                <tr>
                  <td>Fats</td>
                  <td>{{ searchFatAmount }}g</td>
                </tr>
                <tr>
                  <td>Calories</td>
                  <td>{{ searchCaloriesAmount }}</td>
                </tr>
              </tbody>
            </table>

            <div class="form-group">
              <div class="d-grid gap-2 mt-2 d-md-block">
                <button
                  class="btn btn-outline-info"
                  type="button"
                  @click="confirmAddItem()"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <table class="table table-hover">
      <thead>
        <tr class="text-left" style="padding: 0">
          <th class="col-10 col-md-9">Food</th>
          <th class="col-2 col-md-3">
            <div class="d-flex justify-content-end">Delete</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ingredient, index) in ingredients" :key="index">
          <td
            class="text-left overflow-text col-10 col-md-10"
            @click="selectedItem(ingredient)"
          >
            <strong>{{ ingredient.food }}</strong>
            <br />
            {{ ingredient.weight ? ingredient.weight + ingredient.type : "" }}
            cal:
            {{
              ingredient.calories !== null
                ? Number(ingredient.calories).toFixed(0)
                : ""
            }}
            p: {{ ingredient.protein }} c: {{ ingredient.carbs }} f:
            {{ ingredient.fats }}
          </td>
          <td class="col-2 col-md-1 button-column">
            <!-- Change col-md-2 to col-md-1 -->
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-outline-danger btn-sm mx-1"
                @click.stop="deleteRow(index)"
              >
                X
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <material-button
      type="submit"
      class="button-block"
      :disabled="ingredients.length === 0"
      @click="addRecipe()"
      >Add Recipe</material-button
    >
  </div>
</template>

<script>
import MaterialButton from "../../components/MaterialButton.vue";
import MaterialInput from "../../components/MaterialInput.vue";
import { logError } from "../../errors/rollbar.js";
import { getFoods, getFoodDetails } from "../../data/foodSearch/foods.js";
import Nutrition from "../../data/nutrition/nutrition.js";

export default {
  name: "AddReceipe",
  components: {
    MaterialButton,
    MaterialInput,
  },
  data() {
    return {
      recipeName: "",
      totalServings: 1,
      ingredientName: "",
      quantity: 1,
      ingredients: [],
      selectedSearchItem: {
        food: "",
        protein: "",
        carbs: "",
        fats: "",
        calories: "",
        foodPortions: [],
        weight: "",
        originalWeight: "",
        usdaid: "",
      },
      searchedItems: [],
      searchText: "",
      isSearchLoading: false,
      showModal: false,
      myFoodsSearch: false,
      brandedSearch: false,
      showFoodModal: false,
      showSearchFoodModal: false,
      recipeNameValidation: false,
    };
  },
  computed: {
    searchProteinAmount() {
      return Math.round(
        this.selectedSearchItem.protein *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchCarbsAmount() {
      return Math.round(
        this.selectedSearchItem.carbs *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchFatAmount() {
      return Math.round(
        this.selectedSearchItem.fats *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
    searchCaloriesAmount() {
      const calcuatedCalories =
        this.searchProteinAmount * 4 +
        this.searchCarbsAmount * 4 +
        this.searchFatAmount * 9;

      return Math.round(
        calcuatedCalories *
          (this.selectedSearchItem.weight /
            this.selectedSearchItem.originalWeight)
      );
    },
  },
  methods: {
    addIngredient() {
      this.showFoodModal = !this.showFoodModal;
    },
    async addRecipe() {
      if (this.recipeName === "") {
        this.recipeNameValidation = true;
        return;
      } else {
        this.recipeNameValidation = false;
      }

      const items = this.ingredients.map((ingredient) => {
        return {
          food: ingredient.food,
          weight: ingredient.weight,
          type: ingredient.type,
          calories: ingredient.calories,
          protein: ingredient.protein,
          carbs: ingredient.carbs,
          fats: ingredient.fats,
          usdaid: ingredient.usdaid,
        };
      });

      const item = items.reduce((acc, curr) => {
        return {
          food: this.recipeName,
          weight: this.totalServings,
          type: "serving",
          calories: acc.calories + curr.calories,
          protein: acc.protein + curr.protein,
          carbs: acc.carbs + curr.carbs,
          fats: acc.fats + curr.fats,
        };
      });

      const response = await this.$swal.fire({
        title: "Confirm Add Item",
        text: "Are you sure you want to add this to your foods?",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Yes, add it!",
        cancelButtonText: "No, go back",
      });

      if (response.isConfirmed) {
        const foodInsert = await Nutrition.insertFood(item);
        const foodError = foodInsert.error;
        const ingredientInsert = await Nutrition.insertIngredients(
          items,
          foodInsert[0].id
        );
        const ingredientError = ingredientInsert.error;

        if (foodError || ingredientError) {
          this.$swal.fire({
            title: "Error",
            text: foodError.message || ingredientError.message,
            icon: "error",
          });
        } else {
          this.$swal.fire({
            title: "Success",
            text: "Item added successfully",
            icon: "success",
          });
          this.$router.push("/");
        }
      }
    },
    async deleteRow(index) {
      const confirm = await this.$swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this item!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (!confirm.isConfirmed) return;

      this.ingredients.splice(index, 1);
    },
    async searchForFood() {
      this.isSearchLoading = true;

      if (this.myFoodsSearch) {
        this.searchMyFoods();
        return;
      }

      try {
        this.searchedItems = await getFoods(
          this.brandedSearch,
          this.searchText
        );
      } catch (err) {
        logError(err);
      } finally {
        this.isSearchLoading = false;
      }
    },
    async searchMyFoods() {
      this.isSearchLoading = true;
      try {
        const response = await Nutrition.searchMyFoods(this.searchText);

        if (response.error) {
          console.error("Error fetching data:", response.error.message);
          return;
        }

        if (response.length > 0) {
          this.searchedItems = response.map((item) => {
            return {
              food: item.description,
              protein: item.protein,
              carbs: item.carbs,
              fats: item.fats,
              calories: item.calories,
              weight: item.weight,
              type: item.type,
            };
          });
        } else {
          this.searchedItems = [
            {
              food: "No results found",
              protein: "",
              carbs: "",
              fats: "",
              calories: "",
            },
          ];
        }
      } catch (err) {
        console.log(err);
      }
      this.isSearchLoading = false;
    },
    async addItem(item) {
      if (item === null) return;

      this.showSearchFoodModal = true;
      this.isSearchLoading = true;

      this.selectedSearchItem = null;

      console.log(item);

      if (!item.usdaid) {
        this.selectedSearchItem = {
          id: item.id,
          food: item.food,
          protein: item.protein,
          carbs: item.carbs,
          fats: item.fats,
          calories: item.calories,
          foodPortions: [
            {
              amount: 1,
              gramWeight: item.weight,
              measureUnit: {
                name: item.type,
              },
            },
          ],
          weight: item.weight,
          originalWeight: item.weight,
        };

        console.log("this is the non lookup item", this.selectedSearchItem);

        this.isSearchLoading = false;
        return;
      }

      try {
        this.selectedSearchItem = await getFoodDetails(item);
        console.log("this is the item", this.selectedSearchItem);
      } catch (err) {
        console.log(err);
      }

      this.isSearchLoading = false;
    },
    async selectedItem(item) {
      await this.addItem(item);
    },
    async confirmAddItem() {
      const item = this.selectedSearchItem;

      if (item.weight === "") {
        alert("Please enter a weight");
        return;
      }

      item.protein = Math.round(
        item.protein * (item.weight / item.originalWeight)
      );
      item.carbs = Math.round(item.carbs * (item.weight / item.originalWeight));
      item.fats = Math.round(item.fats * (item.weight / item.originalWeight));
      item.calories = Math.round(
        item.calories * (item.weight / item.originalWeight)
      );

      const insertItem = {
        id: item.id,
        food: item.food,
        weight: item.weight,
        type: "g",
        calories: item.calories,
        protein: item.protein,
        carbs: item.carbs,
        fats: item.fats,
        usdaid: item.usdaid,
      };

      this.ingredients.push(insertItem);
      this.showFoodModal = false;
      this.showSearchFoodModal = false;
      this.searchText = "";
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 50;
  width: 800%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scrollable-list {
  max-height: 200px;
  overflow-y: auto;
}

.button-block {
  width: 100%;
}

.overflow-text {
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
