<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-gradient opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Welcome Back 👋
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start mt-3">
                <div class="mb-3">
                  <material-input
                    id="email"
                    v-model="email"
                    type="email"
                    label="email"
                    name="email"
                    :required="true"
                    autocomplete="username"
                  />
                </div>
                <div class="mb-3">
                  <material-input
                    id="password"
                    v-model="password"
                    type="password"
                    label="Password"
                    :required="true"
                    name="password"
                    autocomplete="current-password"
                  />
                </div>
                <p class="mt-0 text-sm text-left">
                  <router-link
                    :to="{ name: 'Reset Cover' }"
                    class="text-info text-gradient font-weight-bold"
                    >Forgot your password?</router-link
                  >
                </p>
                <div class="text-center">
                  <div v-if="isLoading" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <material-button
                    class="my-0 mb-2"
                    variant="gradient"
                    color="info"
                    full-width
                    :disabled="isLoading"
                    @click.prevent="login"
                    >Sign in</material-button
                  >
                </div>
                <p class="mt-4 text-sm text-center">
                  Don't have an account?
                  <a
                    class="text-success text-gradient font-weight-bold"
                    :href="paymentLink"
                    target="_blank"
                    >Sign Up</a
                  >
                  <!-- <router-link
                    :to="{ name: 'Signup' }"
                    class="text-success text-gradient font-weight-bold"
                    >Sign up</router-link
                  > -->
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }} NutriCoachMe
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.nutricoachme.com"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Home</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import supabase from "../../../data/createClient.js";

export default {
  name: "SignIn",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      paymentLink: "",
    };
  },
  beforeMount() {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "dev.nutricoachme.com"
    ) {
      this.paymentLink = process.env.VUE_APP_TEST_PAY_LINK;
    } else {
      this.paymentLink = process.env.VUE_APP_PAY_LINK;
    }
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async login() {
      this.isLoading = true;
      const { data, error } = await supabase.auth.signInWithPassword({
        email: this.email,
        password: this.password,
      });

      console.log(data);

      this.isLoading = false;

      if (error) {
        this.$swal.fire({
          icon: "error",
          title: "Login Failed",
          text: error.message,
        });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
